<template>
  <div>
    <form
      autocomplete="off"
      @submit.prevent="reset()"
      class="auth-form"
    >
      <base-logo-wave class="text-center auth-logo" />

      <h2>{{ $t('auth.reset_password.title') }}</h2>

      <base-input
        v-model="data.password"
        type="password"
        :placeholder="$t('auth.reset_password.new_password_placeholder')"
        :small_text="$t('auth.reset_password.characters_minimum')"
        required
      />

      <base-input
        v-model="data.password_confirmation"
        type="password"
        :placeholder="$t('auth.reset_password.repeat_password_placeholder')"
        :small_text="$t('auth.reset_password.characters_minimum')"
        required
      />

      <base-button type="submit" class="btn-block btn-lg" :disabled="!password_valid">
        {{ $t('auth.reset_password.set_new_password_btn') }}
      </base-button>

      <p class="bottom-text text-center">
        {{ $t('auth.reset_password.already_have_an_account') }}
        &nbsp;<router-link :to="{ name: 'login' }">{{ $t('auth.reset_password.log_in') }}</router-link>
      </p>
    </form>
  </div>
</template>

<script>
import BaseLogoWave from '../../components/BaseLogoWave.vue'
import BaseInput from '../../components/BaseInput.vue'
import BaseButton from '../../components/BaseButton.vue'

export default {
  data () {
    return {
      data: {
        password: '',
        password_confirmation: ''
      },
      headers: {
        'access-token': this.$route.query['access-token'],
        uid: this.$route.query.uid,
        client: this.$route.query.client
      }
    }
  },
  mounted () {
    if (this.headers.uid === undefined || this.headers.client === undefined) {
      this.$router.push({ name: 'default' })
    }
  },
  computed: {
    password_valid () {
      return (this.data.password === this.data.password_confirmation && this.data.password !== '')
    }
  },
  components: {
    BaseLogoWave,
    BaseInput,
    BaseButton
  },
  methods: {
    async reset () {
      await this.$recaptchaLoaded()

      this.$recaptcha('reset_password').then(token => {
        this.axios.put(
          '/subscriber_auth/password',
          this.data,
          {
            headers: this.headers
          }
        ).then(response => {
          this.$notify({
            type: 'success',
            text: this.$t('auth.reset_password.reset_success')
          })

          this.$auth.logout({
            redirect: { name: 'login' }
          })
        }).catch(error => {
          this.data.password = ''
          this.data.password_confirmation = ''

          if (error.response.data.errors[0] !== 'Unauthorized') {
            this.$notify({
              type: 'error',
              text: this.devise().formatResponse(error.response.data)
            })
          }
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
h2 {
  font-size: 14px;
  text-transform: uppercase;
  color: var(--cool-grey);
  margin-bottom: 20px;
  }
</style>
